<template>
  <div class="v-lookup" :class="parentSlug">
    <Breadcrumbs />
    <TheHeading class="v-lookup__title" level="h1">{{
      lookupTitle
    }}</TheHeading>

    <div class="v-lookup__wrapper">
      <Card
        v-for="item in lookupList"
        class="v-lookup__item"
        :key="item.id"
        :title="item.name"
        :description="item.description"
        :item="item"
        :actions="cardActions"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'

import useStructuresLocal from '@/composables/useStructures'
import useTranslations from '@/composables/useTranslations'
import useModal from '@/composables/useModal'
import {
  useStructures,
  useTenant,
  useBreadcrumbs,
  useLocalizations,
} from '@/core'
import Breadcrumbs from '@/components/Breadcrumbs'
import TheHeading from '@/components/TheHeading'
import Card from '@/components/Card'

export default {
  name: 'Lookup',

  components: {
    Breadcrumbs,
    TheHeading,
    Card,
  },

  setup() {
    const loading = ref(true)
    const { locale } = useI18n()
    const { isFallbackLanguage, getTenantTranslation } = useTranslations()
    const { fetchStructuresLocalization } = useLocalizations()
    const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs()
    const { tenant } = useTenant()
    const route = useRoute()
    const { setModal } = useModal()
    const parentSlug = computed(() => route.params.lookupParent)
    const parentSlugPath = computed(() => {
      return `${tenant.value.slug}/${parentSlug.value}`
    })
    const {
      getStructuresBySlugPath,
      fetchStructuresChildren,
      fetchStructuresNode,
    } = useStructures()
    const { getStructuresChildrens } = useStructuresLocal()
    
    const parentStructure = computed(() =>
      getStructuresBySlugPath(
        parentSlugPath.value,
        true,
        'DIRECTORY',
        locale.value,
      ),
    )
    const lookupTitle = computed(
      () => (parentStructure.value && parentStructure.value.name) || '',
    )
    const lookupList = computed(() =>
      getStructuresChildrens(
        parentStructure.value.id,
        'DIRECTORY',
        locale.value,
      ),
    )

    const cardActions = {
      type: 'concept',
      action: (concept) => {
        onShowConcept(concept)
      },
    }
    const onShowConcept = (concept) => {
      setModal('ModalConcept', {
        title: concept.name,
        content: concept,
        type: 'concept',
      })
    }

    useHead({
      title: computed(
        () =>
          `${lookupTitle.value} - ${getTenantTranslation(tenant.value.name)}`,
      ),
    })

    onMounted(async () => {
      clearBreadcrumbs()
      await fetchStructuresNode(parentSlugPath.value)
      try {
        if (!isFallbackLanguage.value) {
          await fetchStructuresLocalization(
            parentStructure.value.id,
            locale.value,
          )
        }
      } catch {
        //
      }
      await fetchStructuresChildren(parentSlugPath.value, {
        limit: 100,
        filter: `{"type": "DIRECTORY"}`,
      })
      try {
        if (!isFallbackLanguage.value) {
          await Promise.all(
            lookupList.value.map(async (item) => {
              await fetchStructuresLocalization(item.id, locale.value)
            }),
          )
        }
      } catch {
        //
      }
      setBreadcrumbs([
        {
          name: lookupTitle.value,
        },
      ])

      loading.value = false
    })

    return {
      lookupTitle,
      parentSlug,
      onShowConcept,
      cardActions,
      lookupList,
    }
  },
}
</script>
